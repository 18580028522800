import React, { useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Input from '../../../../../../../theme/ui/Atoms/Input/Input';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../../../../theme/ui/Atoms/Button/Button';
import { ArrowBackIcon, TrashIcon } from '../../../../../../../assets';
import {
  CONFIRMATION_TYPE,
  ScheduleDayPartAssignmentAPIResponse,
  ScheduleEditAPIResponse,
  WeekDaysISO,
} from '../../../../../../../service/schedule/ScheduleAPI.model';
import ScheduleAPI from '../../../../../../../service/schedule/ScheduleAPI';
import { ScheduleAPIResponse } from '../../../../../../../service/schedule/ScheduleAPI.model';
import useErrorMessage from '../../../../../../../shared/hooks/useErrorMessage';
import useWatchError from '../../../../../../../shared/hooks/useWatchError';
import ZoneSettingsAPI from '../../../../../../../service/zoneSettings/ZoneSettingsAPI';
import TimerPicker from '../../../../../../../theme/ui/Atoms/TimerPicker/TimerPicker';
import {
  momentToTime,
  timeToMoment,
} from '../../../../../../../shared/util/moment/parseTime';
import SelectDisplayControl from '../DayPart/SelectDisplayControl';
import { ZoneSettingWitIdResponse } from '../../../../../../../service/zoneSettings/ZoneSettingsAPI.model';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { useAppSelector } from '../../../../../../../redux/store.model';
import { getFeatureToggle } from '../../../../../../../shared/featureToggle';
import { optionsSelectZones } from '../DayPartNewPage/DayPartNewPage';
import InputSelect from '../../../../../../../theme/ui/Atoms/InputSelect';

const WEEK_DAYS_KEYS = ['1', '2', '3', '4', '5', '6', '7'] as WeekDaysISO[];
const ScheduleValidationSchema = yup.array(
  yup.object({
    partId: yup
      .string()
      .nullable()
      .required('schedule.settings.schedule.dayPart_required'),
    startTime: yup
      .string()
      .nullable()
      .required('schedule.settings.schedule.startTime_required'),
  })
);
const validationSchema = yup.object({
  scheduleName: yup
    .string()
    .required('schedule.settings.schedule.scheduleName_required'),
  zoneCount: yup
    .number()
    .required('schedule.settings.day_part.numberZones_required'),
  schedule: yup.object({
    '1': ScheduleValidationSchema,
    '2': ScheduleValidationSchema,
    '3': ScheduleValidationSchema,
    '4': ScheduleValidationSchema,
    '5': ScheduleValidationSchema,
    '6': ScheduleValidationSchema,
    '7': ScheduleValidationSchema,
  }),
});
const clearInitialValues = {
  scheduleName: '',
  zoneCount: null as number | null | undefined,
  schedule: {
    '1': [],
    '2': [],
    '3': [],
    '4': [],
    '5': [],
    '6': [],
    '7': [],
  } as ScheduleAPIResponse['schedule'],
};

function ScheduleNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [setSchedule] = ScheduleAPI.useSetSchedulesByRegionMutation();
  const errorMessage = useErrorMessage();
  const { scheduleId = '', regionId = '' } = useParams<{
    scheduleId: string;
    regionId: string;
  }>();
  // Edit schedule
  const {
    error: errorSchedules,
    isLoading: isLoadingSchedules,
    isFetching: isFetchingSchedules,
    data: schedules,
  } = ScheduleAPI.useGetSchedulesByRegionQuery(regionId || '');

  const schedule = useMemo(() => {
    const schedule = schedules?.find((sc) => sc.scheduleId === scheduleId);
    if (schedule) return { ...schedule, zoneCount: schedule.zoneCount };
  }, [scheduleId, schedules]);

  const formik = useFormik({
    initialValues: (schedule ??
      clearInitialValues) as typeof clearInitialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      return await setSchedule({
        schedule: values as ScheduleEditAPIResponse,
        regionId: regionId ?? '',
      })
        .unwrap()
        .then(onClickBack)
        .catch(errorMessage);
    },
  });

  // options
  const {
    error: errorDayParts,
    isLoading: isLoadingDayParts,
    isFetching: isFetchingDayParts,
  } = ScheduleAPI.useGetDayPartByRegionQuery(regionId ?? '');
  const {
    isFetching: isFetchingZoneSettings,
    isLoading: isLoadingZoneSettings,
    error: errorZoneSettings,
    data: zoneSettings = [],
  } = ZoneSettingsAPI.useGetZoneSettingsByStoreQuery(regionId || '');

  const dayPartsLoading =
    isLoadingDayParts ||
    isFetchingDayParts ||
    isLoadingZoneSettings ||
    isFetchingZoneSettings;

  const error = errorDayParts || errorZoneSettings || errorSchedules;
  useWatchError(error);

  const onClickBack = () => {
    const dayPartListLink = `/manager/region/${regionId}/schedule-settings/schedule`;
    navigate(dayPartListLink);
  };

  const handleChangeZoneCount = (
    e: React.ChangeEvent<HTMLInputElement>,
    zoneCount: number
  ) => {
    formik.setFieldValue('zoneCount', zoneCount);
    formik.setFieldValue('schedule.1', []);
  };
  if (isLoadingSchedules || isFetchingSchedules)
    return (
      <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );

  return (
    <Container maxWidth="sm">
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Input
            id={`scheduleName`}
            name={`scheduleName`}
            label={t(`schedule.settings.schedule.scheduleName`)}
            placeholder={t(
              `schedule.settings.schedule.scheduleName_placeholder`
            )}
            value={formik.values?.scheduleName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.scheduleName &&
                formik.errors.scheduleName &&
                t(formik.errors.scheduleName)
            )}
            helperText={
              formik.touched.scheduleName &&
              formik.errors.scheduleName &&
              t(formik.errors.scheduleName)
            }
            required
            aria-required
            fullWidth
          />

          <InputSelect
            id={`zoneCount`}
            name={`zoneCount`}
            label={t(`schedule.settings.day_part.numberZones`)}
            value={formik.values?.zoneCount}
            defaultValue={2}
            onChange={handleChangeZoneCount}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.zoneCount && formik.errors.zoneCount)}
            helperText={
              formik.touched.zoneCount && formik.errors.zoneCount
                ? t(formik.errors.zoneCount) + formik.values?.zoneCount
                : t('schedule.settings.day_part.numberZones_help_text')
            }
            required
            aria-required
            fullWidth
            options={optionsSelectZones}
          />

          {/* make accordion with the weekdays */}

          {!!formik.values.zoneCount && (
            <div>
              {WEEK_DAYS_KEYS.map((weekday) => (
                <Accordion key={weekday}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls={`weekday-content-${weekday}`}
                    id={`weekday-header-${weekday}`}
                  >
                    <Typography>
                      {t(`schedule.settings.schedule.weekdays.${weekday}`)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ScheduleDaySelect
                      formik={formik}
                      weekDay={weekday}
                      regionId={regionId}
                      scheduleId={scheduleId}
                      zoneSettings={zoneSettings}
                      loading={dayPartsLoading}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          )}
          {/* debug form */}
          {/* <p style={{ width: 280, wordWrap: 'break-word' }}>
            {JSON.stringify({
              c: formik.submitCount,
              v: formik.values,
              e: formik.errors,
              t: formik.touched,
            })}
          </p> */}
        </Stack>

        <Box
          className="timer-settings-footer"
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginY: 4,
          }}
        >
          <Box>
            <Button
              variant="contained-gray"
              sx={{ height: '3em' }}
              onClick={onClickBack}
              disabled={formik.isSubmitting}
            >
              <ArrowBackIcon height={'1em'} />
            </Button>
          </Box>
          <Button
            variant="contained"
            type="submit"
            size="large"
            rounded
            disabled={formik.isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Box>
      </form>
    </Container>
  );
}

interface ScheduleDaySelectProps {
  formik: ReturnType<typeof useFormik<typeof clearInitialValues>>;
  regionId: string;
  scheduleId: string;
  weekDay: WeekDaysISO;
  zoneSettings: ZoneSettingWitIdResponse[];
  loading: boolean;
}
function ScheduleDaySelect(props: ScheduleDaySelectProps) {
  const { t } = useTranslation();
  const { formik, weekDay, loading, regionId, scheduleId, zoneSettings } =
    props;

  const onClickAddDayPart = () => {
    const dayPartAssigned: ScheduleDayPartAssignmentAPIResponse = {
      partId: '',
      startTime: '',
      confirmationType: CONFIRMATION_TYPE.NO_CONFIRMATION_REQUIRED,
    };
    const lastValues = formik.values.schedule[weekDay] ?? [];
    formik.setFieldValue(`schedule.${weekDay}`, [
      ...lastValues,
      dayPartAssigned,
    ]);
  };
  const onClickDeleteDayPart = (index: number) => () => {
    formik.setFieldValue(
      `schedule.${weekDay}`,
      formik.values.schedule[weekDay]?.filter((_, i) => i !== index)
    );
  };

  const confimableFeatureToggle =
    useAppSelector(getFeatureToggle).ScheduleNonConfirmable;
  const onChangeConfirmable = (index: number) => () => {
    formik.setFieldValue(
      `schedule.${weekDay}.${index}.confirmationType`,
      formik.values.schedule[weekDay]?.[index].confirmationType ===
        CONFIRMATION_TYPE.CLOUD_CONFIRMATION_REQUIRED
        ? CONFIRMATION_TYPE.NO_CONFIRMATION_REQUIRED
        : CONFIRMATION_TYPE.CLOUD_CONFIRMATION_REQUIRED
    );
  };

  return (
    <FormControl component="div" fullWidth>
      <FormLabel component="legend">
        {t('schedule.settings.schedule.schedule')}
      </FormLabel>

      <Box sx={{ p: 2 }}>
        {formik.values.schedule[weekDay]?.map((dayPart, index) => {
          const startTimeString = dayPart.startTime;
          const startTimeMoment = timeToMoment(startTimeString);
          const errorFormDic: any = formik.errors.schedule?.[weekDay]?.[index];
          const errorForm = Boolean(
            errorFormDic?.partId || errorFormDic?.startTime
          );
          const errorValue: string | undefined =
            errorFormDic?.partId || errorFormDic?.startTime;

          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                my: 2,
              }}
            >
              <Box sx={{ flexGrow: 1, mr: 1 }}>
                <SelectDisplayControl
                  regionId={regionId}
                  scheduleId={scheduleId}
                  numberZones={formik.values.zoneCount}
                  zoneSettings={zoneSettings}
                  loading={loading}
                  InputProps={{
                    label: t(`schedule.settings.schedule.dayPart`),
                    placeholder:
                      t(`schedule.settings.schedule.dayPart_placeholder`) ?? '',
                    error: errorForm,
                    helperText: (errorValue && t(errorValue)) ?? '',
                    required: true,
                    fullWidth: true,
                  }}
                  value={dayPart.partId}
                  onChange={(e: any, v: string) => {
                    formik.setFieldValue(
                      `schedule.${weekDay}.${index}.partId`,
                      v
                    );
                  }}
                  onBlur={() =>
                    formik.setFieldTouched(
                      `schedule.${weekDay}.${index}.partId`,
                      true
                    )
                  }
                />
              </Box>
              <Box>
                <FormControl component="fieldset">
                  <FormLabel component="legend" required>
                    {t('schedule.settings.schedule.startTime')}
                  </FormLabel>
                  <TimerPicker
                    value={startTimeMoment}
                    onChange={(value) =>
                      formik.setFieldValue(
                        `schedule.${weekDay}.${index}.startTime`,
                        momentToTime(value)
                      )
                    }
                    InputProps={{
                      required: true,
                      error: !startTimeMoment?.isValid(),
                      label: t('schedule.settings.schedule.startTime'),
                      placeholder: 'hh:mm',
                      sx: {
                        '.MuiInput-input': { width: '80px' },
                      },
                    }}
                  />
                </FormControl>
              </Box>
              {!!confimableFeatureToggle && (
                <Box ml={1}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {t('schedule.settings.schedule.confirmable')}
                    </FormLabel>
                    <Checkbox
                      checked={
                        formik.values.schedule[weekDay]?.[index]
                          .confirmationType ===
                        CONFIRMATION_TYPE.CLOUD_CONFIRMATION_REQUIRED
                      }
                      indeterminate={
                        formik.values.schedule[weekDay]?.[index]
                          .confirmationType === CONFIRMATION_TYPE.NOT_SPECIFIED
                      }
                      onChange={onChangeConfirmable(index)}
                      onBlur={() =>
                        formik.setFieldTouched(
                          `schedule.${weekDay}.${index}.confirmationType`,
                          true
                        )
                      }
                      sx={{ borderRadius: '3em' }}
                    />
                  </FormControl>
                </Box>
              )}
              {/* label padding */}
              <Box pt={'1.4375em'}>
                <IconButton
                  onClick={onClickDeleteDayPart(index)}
                  sx={{ height: 'fit-content', ml: 1 }}
                >
                  <TrashIcon />
                </IconButton>
              </Box>
            </Box>
          );
        })}

        <Button variant="text" onClick={onClickAddDayPart}>
          {t('schedule.settings.schedule.add_new_day_part')}
        </Button>
      </Box>
    </FormControl>
  );
}

export default ScheduleNewPage;
