import { styled } from '@mui/material';
import React from 'react';
import { ZoneSettingResponse } from '../../../../service/store/modules/zone/ZoneService.model';
import { getBackgroundColor, getBackgroundColorHover } from '../ZoneButton';
import { LightIcon, PowerIcon } from '../../../../assets';
import Button from '../Button/Button';
import { Temperature } from '../../../../service/temperaturePreference/Temperature';
import { useTemperaturePreference } from '../../../../service/temperaturePreference';
import { TEMPERATURE_UNIT_TYPES } from '../../../../service/temperaturePreference';
import { useTranslation } from 'react-i18next';

const Container = styled('div')<{
  isOff?: boolean;
  isLightOnly?: boolean;
  isEmpty?: boolean;
}>(({ theme, isOff, isLightOnly, isEmpty }) => ({
  width: '100%',
  aspectRatio: '13/9',
  fontSize: '1.1em',
  fontWeight: 600,
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: getBackgroundColor(
    theme,
    undefined,
    undefined,
    isOff || isEmpty
  ),
  '&:hover': {
    backgroundColor: getBackgroundColorHover(
      theme,
      undefined,
      undefined,
      isOff || isEmpty
    ),
  },
  color: isLightOnly ? theme.palette.primary.main : isOff ? 'white' : 'black',
  borderRadius: '10%',
  backgroundImage: isOff ? theme.palette.gradientColor.extended : undefined,
}));
interface ZoneSettingButtonProps {
  zoneSettingId?: string | null | undefined;
  zoneSetting?: ZoneSettingResponse;
}

const Sup = styled('sup')({
  fontSize: '0.5em',
});

function ZoneSettingButton(props: ZoneSettingButtonProps) {
  return (
    <Button
      color="inherit"
      variant="text"
      sx={{
        width: '100%',
        fontSize: '1em',
      }}
    >
      {<ZoneSettingContainer {...props} />}
    </Button>
  );
}

export function ZoneSettingContainer(props: ZoneSettingButtonProps) {
  const { zoneSettingId } = props;
  const isOff = zoneSettingId === 'OFF';
  const isLightOnly = zoneSettingId === 'LIGHTS_ONLY';
  const isEmpty = !zoneSettingId;

  return (
    <Container isOff={isOff} isLightOnly={isLightOnly} isEmpty={isEmpty}>
      {<ZoneSettingContentButton {...props} />}
    </Container>
  );
}

function ZoneSettingContentButton(props: ZoneSettingButtonProps) {
  const { zoneSettingId, zoneSetting } = props;
  const { t } = useTranslation();
  const isOff = zoneSettingId === 'OFF';
  const isLightOnly = zoneSettingId === 'LIGHTS_ONLY';
  const isEmpty = !zoneSettingId;
  const isManual = zoneSettingId === 'MANUAL';
  const temperaturePreference = useTemperaturePreference();
  const value =
    zoneSetting?.setpoint &&
    (temperaturePreference === TEMPERATURE_UNIT_TYPES.CELSIUS
      ? Temperature.round(zoneSetting?.setpoint)
      : Temperature.convertCelsiusToFahrenheitRound(zoneSetting?.setpoint));

  const temperaturePreferenceLabel = t(
    `settings.temperature_preference.${temperaturePreference}`
  );
  if (isOff) {
    return <PowerIcon height="1.4em" />;
  }
  if (isLightOnly) {
    return <LightIcon height="1.4em" />;
  }
  if (isManual) {
    return <>{t('general.on')}</>;
  }
  if (isEmpty) {
    return null;
  }

  return (
    <>
      {value}
      <Sup>{temperaturePreferenceLabel}</Sup>
    </>
  );
}

export default ZoneSettingButton;
