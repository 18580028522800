import { DayPartAPIResponse } from '../ScheduleAPI.model';
import _ from 'lodash';

function getNumberZonesDayPart(
  shelfSettings: DayPartAPIResponse['shelfSettings']
) {
  return _.max(shelfSettings.map((item) => parseInt(item.zoneId)));
}

export default getNumberZonesDayPart;
