import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import CircularProgress from '@mui/material/CircularProgress';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import useDevicesOperator from '../../../../service/store/hooks/useDevicesOperator';
import ZoneConnected from './ZoneConnected';

export default function DeviceOperation() {
  const { t } = useTranslation();
  const { devices, N_ZONES } = useDevicesOperator();

  const grid = useMemo(
    () =>
      new Array(N_ZONES).fill('NumberOfZones').map((_, indexZone) =>
        devices?.map((device) => {
          const zone =
            device?.shelfSettings?.[
              `${indexZone + 1}` as keyof typeof device.shelfSettings
            ];
          return {
            zone:
              zone?.shelfStatus?.operatingMode !== 'UNUSED' ? zone : undefined,
            device: device,
          };
        })
      ),
    [N_ZONES, devices]
  );

  // redirect if there only one device
  if (devices?.length === 1)
    return <Navigate to={`/operation/timers/${devices[0].uuid}`} replace />;
  return (
    <Stack direction={'row'} spacing={2} marginTop={3}>
      {grid?.map((column, index) => {
        const zoneIndex = index + 1;
        return (
          <div key={zoneIndex}>
            <Typography
              variant="subtitle2"
              color={'primary'}
              textTransform={'uppercase'}
              textAlign={'center'}
              sx={{ marginBottom: 2 }}
            >
              {t('timers.zone')} {zoneIndex}
            </Typography>
            <Stack direction={'column'} spacing={1}>
              {column?.map((item) =>
                item.zone ? (
                  <ZoneConnected
                    key={`${item.device.unitId}-${zoneIndex}`}
                    item={item}
                    zoneIndex={zoneIndex}
                  />
                ) : (
                  <Box
                    key={`${item.device.unitId}-${zoneIndex}`}
                    sx={{
                      height: 90,
                      width: 130,
                    }}
                  />
                )
              )}
            </Stack>
          </div>
        );
      })}
    </Stack>
  );
}
