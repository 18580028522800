import { createSelector } from '@reduxjs/toolkit';
import LocationAPI from '../LocationAPI';
import { getRegionSelector } from './getRegionSelector';
import { filterNotEmpty } from '../../../shared/ArrayUtil';

export const getDevicesByRegionSelector = createSelector(
  [
    (state) => LocationAPI.endpoints.getLocations.select()(state),
    getRegionSelector,
  ],
  (locationsData, region) =>
    region?.devicesIds
      .map((deviceId) =>
        locationsData.data?.devices.find((device) => device.uuid === deviceId)
      )
      .filter(filterNotEmpty) ?? []
);
