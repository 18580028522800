import { useCallback } from 'react';
import { ZoneSettingWitIdResponse } from '../../zoneSettings/ZoneSettingsAPI.model';
import { useAppSelector } from '../../../redux/store.model';
import { filterByTemperatureRestriction } from '../selectors/filterValidSchedulesSelector';
import ScheduleAPI from '../ScheduleAPI';
import { getDevicesByRegionSelector } from '../../locations/selectors/getDevicesByRegionSelector';
import { filterNotEmpty } from '../../../shared/ArrayUtil';
import ZoneSettingsAPI from '../../zoneSettings/ZoneSettingsAPI';
import { DayPartAPIResponse, ScheduleAPIResponse } from '../ScheduleAPI.model';

interface IValidateZoneSettingsOptions {
  settings?: ZoneSettingWitIdResponse[];
  dayParts?: DayPartAPIResponse[];
  schedules?: ScheduleAPIResponse[];
}
const useValidateTemperatureRestriction = (regionId: string) => {
  const devices = useAppSelector((state) =>
    getDevicesByRegionSelector(state, { regionId })
  );
  const schedulesData = ScheduleAPI.useGetSchedulesByRegionQuery(regionId);
  const dayPartsData = ScheduleAPI.useGetDayPartByRegionQuery(regionId);
  const settingsData = ZoneSettingsAPI.useGetZoneSettingsByStoreQuery(regionId);

  const validateSettingsRegion = useCallback(
    ({
      settings = settingsData.data ?? [],
      dayParts = dayPartsData.data ?? [],
      schedules = schedulesData.data ?? [],
    }: IValidateZoneSettingsOptions) => {
      const deviceMapErrors = devices.map((device) => {
        const schedule = schedules.find(
          (schedule) => device.schedule === schedule.scheduleId
        );
        const errors =
          schedule &&
          filterByTemperatureRestriction(schedule, device, dayParts, settings);

        if (errors?.length) {
          return {
            visible: true,
            schedule,
            device,
            valid: false,
            errors,
            error: 'schedule.error.temperatureSetpointLimits',
          };
        }
        return null;
      });

      return deviceMapErrors.filter(filterNotEmpty);
    },
    [dayPartsData.data, devices, schedulesData.data, settingsData.data]
  );
  return validateSettingsRegion;
};

export default useValidateTemperatureRestriction;
