import StoreAPI from '../StoreAPI';
import useWatchError from '../../../shared/hooks/useWatchError';
import { M_SECONDS_POOLING_INTERVAL_DATA } from '../Store.constants';
import { UseQuerySubscription } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useMemo } from 'react';
import _ from 'lodash';

type Options = Parameters<UseQuerySubscription<any>>[1];
function useDevicesOperator(options: Options = {}) {
  const responseAPI = StoreAPI.useGetStoreInfoDetailsQuery(undefined, {
    pollingInterval: M_SECONDS_POOLING_INTERVAL_DATA,
    ...options,
  });

  const { data: storeInfoDetails, ...rest } = responseAPI;
  const devices = storeInfoDetails?.devices ?? [];
  const N_ZONES = useMemo(
    () =>
      _.max(
        storeInfoDetails?.devices?.map(
          (device) =>
            _.max(
              device.shelfMappings.map((zone) => (zone ? zone.id : null))
            ) ?? 0
        )
      ),
    [storeInfoDetails?.devices]
  );

  useWatchError(rest.error);
  return {
    devices,
    N_ZONES,
    ...rest,
  };
}

export default useDevicesOperator;
