import React from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import ModalInfo, {
  ModalInfoProps,
} from '../../../../theme/ui/Atoms/Modal/ModalInfo/ModalInfo';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import useValidateTemperatureRestriction from '../../../../service/schedule/hook/useValidateZoneSettings';
import {
  Temperature,
  TEMPERATURE_UNIT_TYPES,
} from '../../../../service/temperaturePreference';

interface TemperatureSetpointLimitsModalErrorProps
  extends Omit<ModalInfoProps, 'open'>,
    InstanceProps<unknown> {
  // react-modal-promise
  isOpen: boolean;
  onResolve: (arg: any) => void;
  onReject: (arg: any) => void;
  temperaturePreference: TEMPERATURE_UNIT_TYPES;
  errors: ReturnType<ReturnType<typeof useValidateTemperatureRestriction>>;
  description?: React.ReactNode;
  followings?: React.ReactNode;
}
function TemperatureSetpointLimitsModalError(
  props: TemperatureSetpointLimitsModalErrorProps
) {
  const {
    isOpen,
    onResolve,
    onReject,
    temperaturePreference,
    description,
    followings,
    ...rest
  } = props;

  const { t } = useTranslation();
  const onClose = () => {
    return onReject(t('schedule.error.temperatureSetpointLimits'));
  };
  return (
    <ModalInfo
      {...rest}
      open={isOpen}
      onClose={onClose}
      title={t('schedule.temperatureSetpointLimits.zoneSettingsModal.title')}
      buttonActions={
        <Button autoFocus variant="contained" onClick={onClose}>
          {t('button.close')}
        </Button>
      }
      description={
        <div>
          <Typography>{description}</Typography>
          <ul>
            {props.errors.map((deviceError, i) => {
              return (
                <li key={deviceError.device.uuid}>
                  <Typography>
                    {t('view_locations.header_store')}:{' '}
                    {deviceError.device.storeName},{' '}
                    {t('view_locations.header_device_name')}:{' '}
                    {deviceError.device.unitName},
                    {t('schedule.settings.schedule.zone')}:{' '}
                    {deviceError.device.model},{' '}
                    {t('schedule.settings.view_locations.schedule_header')}:{' '}
                    {deviceError.schedule?.scheduleName}
                  </Typography>
                  <ul>
                    {deviceError.errors.map((error, i) => {
                      const deviceTemperatureRestriction =
                        error.device.shelfSettings?.[error.zoneId].shelfStatus
                          .temperatureSetpointLimits;
                      const min = !deviceTemperatureRestriction?.min
                        ? '-inf'
                        : temperaturePreference === 'FAHRENHEIT'
                        ? Temperature.convertCelsiusToFahrenheitRound(
                            deviceTemperatureRestriction.min
                          )
                        : deviceTemperatureRestriction.min;
                      const max = !deviceTemperatureRestriction?.max
                        ? 'inf'
                        : temperaturePreference === 'FAHRENHEIT'
                        ? Temperature.convertCelsiusToFahrenheitRound(
                            deviceTemperatureRestriction.max
                          )
                        : deviceTemperatureRestriction.max;
                      const setpoint = !error.setting?.setpoint
                        ? '-/-'
                        : temperaturePreference === 'FAHRENHEIT'
                        ? Temperature.convertCelsiusToFahrenheitRound(
                            error.setting?.setpoint
                          )
                        : error.setting?.setpoint;

                      return (
                        <li key={i}>
                          <Typography>
                            {t('schedule.settings.schedule.dayPart')}:{' '}
                            {error.dayPart.displayName},{' '}
                            {t('schedule.settings.day_part.shelfSetting')}:{' '}
                            {error.setting?.displayName},{' '}
                            {t('schedule.settings.schedule.zone')}:{' '}
                            {error.zoneId},{' '}
                            {t(
                              'schedule.temperatureSetpointLimits.zoneSettingsModal.restriction_detail',
                              {
                                min,
                                max,
                                setpoint,
                              }
                            )}
                          </Typography>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
          <Typography>{followings}</Typography>
        </div>
      }
    ></ModalInfo>
  );
}

export const temperatureSetpointLimitsModalErrorPromise = create(
  TemperatureSetpointLimitsModalError
);

export default TemperatureSetpointLimitsModalError;
