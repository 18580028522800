import React from 'react';
import {
  DayPartAPIResponse,
  ScheduleAPIResponse,
} from '../../../../../../../service/schedule/ScheduleAPI.model';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Typography,
  styled,
} from '@mui/material';
import { ZoneSettingWitIdResponse } from '../../../../../../../service/zoneSettings/ZoneSettingsAPI.model';
import { emptyArray } from '../../../../../../../shared/ArrayUtil';
import ZonesSettingsBoxes from '../ZoneSettingsSmall/ZonesSettingsBoxes';
import getWeekDaysNotEmpty from '../../../../../../../service/schedule/util/getWeekDaysNotEmpty';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { CONFIRMATIONS_CLOUD } from '../../../../../../../service/store/modules/schedule/selectors/schedule.selectors';

const Ul = styled('ul')`
  padding: 0 0 0 1rem;
`;

export interface DayPartDisplayProps {
  schedule: ScheduleAPIResponse['schedule'];
  zoneSettings: ZoneSettingWitIdResponse[];
  dayParts?: DayPartAPIResponse[];
  loading?: boolean;
}
function DayPartsDisplay(props: DayPartDisplayProps) {
  const {
    schedule,
    zoneSettings,
    dayParts = emptyArray as DayPartAPIResponse[],
    loading,
  } = props;
  const { t } = useTranslation();
  if (loading) return <CircularProgress />;
  const weekDaysConfigured = getWeekDaysNotEmpty(schedule);
  if (!weekDaysConfigured.length) return null;

  return (
    <>
      {weekDaysConfigured.map(([weekday, list]) => (
        <Accordion key={weekday} variant="elevation" elevation={3}>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls={`weekday-content-${weekday}`}
            id={`weekday-header-${weekday}`}
          >
            <Typography>
              {t(`schedule.settings.schedule.weekdays.${weekday}`)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Ul key={weekday}>
              {list?.map((item, i) => {
                const dayPart = dayParts.find(
                  (dayPart) => dayPart.id === item.partId
                );
                return (
                  <Typography
                    key={`${i}-${item.partId}`}
                    sx={{ mb: 1 }}
                    component={'li'}
                  >
                    <Typography>{dayPart?.displayName}</Typography>
                    <Typography variant="body2">
                      {item.startTime}{' '}
                      <span>
                        {CONFIRMATIONS_CLOUD.includes(item.confirmationType)
                          ? `(${t('schedule.settings.schedule.confirmable')})`
                          : null}
                      </span>
                    </Typography>

                    {!!dayPart && (
                      <ZonesSettingsBoxes
                        shelfSettings={dayPart.shelfSettings}
                        zoneSettings={zoneSettings}
                        loading={loading}
                      />
                    )}
                  </Typography>
                );
              })}
            </Ul>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}

export default DayPartsDisplay;
