import { createSelector } from '@reduxjs/toolkit';
import LocationAPI from '../LocationAPI';

export const getRegionSelector = createSelector(
  [
    LocationAPI.endpoints.getLocations.select(),
    (state, props: { regionId?: string }) => props.regionId,
  ],
  (locations, regionId) =>
    locations.data?.regions.find((region) => region.groupId === regionId)
);
