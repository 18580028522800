import { createSelector } from '@reduxjs/toolkit';
import { filterNotEmpty } from '../../../shared/ArrayUtil';
import { DeviceWithIncidents } from '../../locations/Location.model';
import ZoneSettingsAPI from '../../zoneSettings/ZoneSettingsAPI';
import ScheduleAPI from '../ScheduleAPI';
import { DayPartAPIResponse, ScheduleAPIResponse } from '../ScheduleAPI.model';
import { ZoneSettingWitIdResponse } from '../../zoneSettings/ZoneSettingsAPI.model';

function filterNumberZonesSchedules(
  schedule: ScheduleAPIResponse,
  nZones: number
) {
  return schedule.zoneCount === nZones;
}

export const filterByTemperatureRestriction = (
  schedule: ScheduleAPIResponse,
  device: DeviceWithIncidents | undefined,
  dayParts: DayPartAPIResponse[],
  settings: ZoneSettingWitIdResponse[]
) => {
  const dayPartIds = Object.values(schedule.schedule)
    .flatMap((dayPart) => dayPart)
    .map((dayPart) => dayPart?.partId);
  const temperatureSetpointLimitsKeys = Object.values(
    device?.shelfSettings ?? {}
  )
    .map((value) => value?.shelfStatus.temperatureSetpointLimits)
    .filter(filterNotEmpty);

  if (temperatureSetpointLimitsKeys.length === 0 || !device) return [];

  const dayPartsFiltered = dayParts.filter((dayPart) =>
    dayPartIds.includes(dayPart.id)
  );

  // find settings from day part and match with zone settings
  const inValidShelfSettings = dayPartsFiltered.flatMap((dayPart) =>
    dayPart.shelfSettings
      .filter(
        (shelfSetting) =>
          !deviceAcceptsTemperature(
            device,
            shelfSetting.zoneId,
            settings.find((setting) => setting.id === shelfSetting.setting)
              ?.setpoint
          )
      )
      .map((shelfSetting) => ({
        valid: false,
        error: 'schedule.error.temperatureSetpointLimits',
        device,
        schedule,
        zoneId: shelfSetting.zoneId,
        setting: settings.find(
          (setting) => setting.id === shelfSetting.setting
        ),
        dayPart,
      }))
  );

  return inValidShelfSettings;
};

const filterValidSchedulesSelector = createSelector(
  [
    (state, { device }: { device?: DeviceWithIncidents }) => device,
    (state: unknown, { nZones }: { nZones: number }) => nZones,
    (state, { regionId }: { regionId: string }) =>
      ScheduleAPI.endpoints.getSchedulesByRegion.select(regionId)(state),
    (state, { regionId }: { regionId: string }) =>
      ScheduleAPI.endpoints.getDayPartByRegion.select(regionId)(state),
    (state, { regionId }: { regionId: string }) =>
      ZoneSettingsAPI.endpoints.getZoneSettingsByStore.select(regionId)(state),
  ],
  (device, nZones, schedulesData, dayPartsData, settingsData) => {
    const schedules = schedulesData.data ?? [];

    schedules.filter((schedule) =>
      filterNumberZonesSchedules(schedule, nZones)
    );

    const scheduleMapFilter = schedules.map((schedule) => {
      if (!filterNumberZonesSchedules(schedule, nZones)) {
        return {
          visible: false,
          schedule,
          valid: false,
          error: 'schedule.error.nZones',
        };
      }
      if (
        filterByTemperatureRestriction(
          schedule,
          device,
          dayPartsData.data ?? [],
          settingsData.data ?? []
        ).length
      ) {
        return {
          visible: true,
          schedule,
          valid: false,
          error: 'schedule.error.temperatureSetpointLimits',
        };
      }
      return {
        visible: true,
        schedule,
        valid: true,
      };
    });

    // filter out not visible schedules
    return scheduleMapFilter.filter((schedule) => schedule.visible);
  }
);

export function deviceAcceptsTemperature(
  device: DeviceWithIncidents,
  zoneId: string,
  settingSetpoint?: number
) {
  const zone = device.shelfSettings?.[zoneId];

  if (!zone) return true;
  return acceptsTemperatureLimits(
    zone.shelfStatus.temperatureSetpointLimits,
    settingSetpoint
  );
}

export function acceptsTemperatureLimits(
  temperatureSetpointLimits: { min?: number; max?: number } | undefined,
  settingSetpoint?: number
) {
  // if settingSetpoint is undefined, it means that there is no restriction
  // sometimes also 0. because backend put 0 to not have the entity without value
  if (settingSetpoint === undefined || settingSetpoint === 0) return true;

  const min = temperatureSetpointLimits?.min;
  const max = temperatureSetpointLimits?.max;

  // if min or max is undefined, it means that there is no restriction

  return (
    (min === undefined || min <= settingSetpoint) &&
    (max === undefined || max >= settingSetpoint)
  );
}

export default filterValidSchedulesSelector;
