import React from 'react';
import { InputProps } from '../../../../../../../theme/ui/Atoms/Input/Input';
import { StyledInputSelect } from '../../../../../../../theme/ui/Atoms/InputSelect/InputSelect';
import ZonesSettingsBoxes from '../ZoneSettingsSmall/ZonesSettingsBoxes';
import { ZoneSettingWitIdResponse } from '../../../../../../../service/zoneSettings/ZoneSettingsAPI.model';
import { ModalInfo } from '../../../../../../../theme/ui/Atoms/Modal';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import Button from '../../../../../../../theme/ui/Atoms/Button/Button';
import { ArrowBackIcon } from '../../../../../../../assets';
import { Link as RouterLink } from 'react-router-dom';
import { useAppSelector } from '../../../../../../../redux/store.model';
import getValidDayPartOptionsByZoneIdSelector from '../../../../../../../service/schedule/selectors/getValidDayPartOptionsByZoneIdSelector';

interface SelectDisplayControlProps {
  InputProps: InputProps;
  /** id displayPart */
  value: string;
  scheduleId: string;
  regionId: string;
  numberZones?: number | null;
  zoneSettings: ZoneSettingWitIdResponse[];
  onBlur?: () => void;
  onChange?: (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    v: string
  ) => void;
  loading?: boolean;
}

function SelectDisplayControl(props: SelectDisplayControlProps) {
  const {
    InputProps,
    value,
    zoneSettings,
    numberZones,
    regionId,
    scheduleId,
    onBlur,
    onChange,
    loading,
  } = props;
  const { t } = useTranslation();
  const dayParts = useAppSelector((state) =>
    getValidDayPartOptionsByZoneIdSelector(state, {
      numberZones: numberZones ?? 0,
      regionId,
      scheduleId,
    })
  );
  const dayPart = dayParts.find((dp) => dp.dayPart.id === value)?.dayPart;
  const valueString = dayPart?.displayName;
  const [open, setOpen] = React.useState(false);
  const onClose = () => {
    setOpen(false);
    onBlur && onBlur();
  };

  const onClickItem = (e: React.MouseEvent<any, MouseEvent>, v: string) => {
    setOpen(false);
    onBlur && onBlur();
    onChange && onChange(e, v);
  };
  const dayPartNewLink = `/manager/region/${regionId}/schedule-settings/day-part/new`;

  if (loading) return <CircularProgress />;
  if (!numberZones) return null;

  return (
    <>
      <StyledInputSelect
        {...InputProps}
        onClick={() => {
          setOpen(true);
        }}
      >
        {valueString}
      </StyledInputSelect>
      <ModalInfo
        maxWidth="md"
        PaperProps={{
          sx: {
            width: '100%',
          },
        }}
        open={open}
        onClose={onClose}
        title={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>{t('schedule.settings.schedule.dayPart_placeholder')}</div>
            <Button
              variant="contained"
              rounded
              sx={{ m: 2 }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore forward params not working in TS
              to={dayPartNewLink}
              component={RouterLink}
            >
              {t('schedule.settings.day_part.new')}
            </Button>
          </Box>
        }
        description={
          <List>
            {dayParts.map(({ dayPart, error, disabled }, index) => {
              const primary = error
                ? `${dayPart.displayName} (${t(error)})`
                : dayPart.displayName;
              return (
                <ListItemButton
                  divider={index !== dayParts.length - 1}
                  key={dayPart.id}
                  onClick={(e) => onClickItem(e, dayPart.id)}
                  disabled={disabled}
                >
                  <ListItemText
                    primary={primary}
                    secondaryTypographyProps={{ component: 'div' }}
                    secondary={
                      <ZonesSettingsBoxes
                        zoneSettings={zoneSettings}
                        shelfSettings={dayPart.shelfSettings}
                      />
                    }
                  />
                </ListItemButton>
              );
            })}
          </List>
        }
        buttonActions={
          <Button
            variant="contained-gray"
            sx={{ height: '3em' }}
            onClick={onClose}
          >
            <ArrowBackIcon height={'1em'} />
          </Button>
        }
      />
    </>
  );
}

export default SelectDisplayControl;
