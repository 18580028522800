import { ZoneSettingWitIdResponse } from '../ZoneSettingsAPI.model';
import { auxiliaryOptions } from './auxiliaryOptions';
import getOptionsFromZoneSettings from './getOptionsFromZoneSettings';

const defaultOptions = [
  { value: 'OFF', text: 'Off', setpoint: undefined },
  { value: 'LIGHTS_ONLY', text: 'Lights Only', setpoint: undefined },
];

function getOptionsFromZoneSettingsWithDefaults(
  zoneSettings: ZoneSettingWitIdResponse[] | undefined,
  includeAuxiliary = false
) {
  return [
    ...defaultOptions,
    ...(includeAuxiliary ? [auxiliaryOptions[1]] : []),
    ...getOptionsFromZoneSettings(zoneSettings),
  ];
}

export default getOptionsFromZoneSettingsWithDefaults;
