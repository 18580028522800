import { useSelector } from 'react-redux';
import { ZoneSettingsResponse } from '../ZoneService.model';
import { getFeatureToggle } from '../../../../../shared/featureToggle';
import { auxiliaryOptions } from '../../../../zoneSettings/serialize/auxiliaryOptions';
import { DevicesStoreInfoResponse } from '../../../StoreService.model';
import { deviceAcceptsTemperature } from '../../../../schedule/selectors/filterValidSchedulesSelector';
import { ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * from zoneSettings from device get a list of options for select
 * @param device
 * @return zoneOptions
 */
export function useGetZoneOptions(
  zoneSettings: ZoneSettingsResponse | undefined,
  zoneIndex: number,
  device: DevicesStoreInfoResponse
) {
  const featureToggle = useSelector(getFeatureToggle);
  const { t } = useTranslation();

  if (featureToggle?.Zone5Zone6Auxiliary && zoneIndex > 4) {
    return auxiliaryOptions;
  }
  if (zoneSettings) {
    return Object.keys(zoneSettings.configured)
      .filter((key) => zoneSettings.configured[key]?.visible)
      .map((key) => {
        const zoneSetting = zoneSettings.configured[key];
        const setpoint = zoneSetting?.setpoint;
        if (zoneSetting?.displayName === 'Off') debugger;
        const disabled = !deviceAcceptsTemperature(
          device,
          zoneIndex.toString(),
          setpoint
        );

        const primary = zoneSetting?.displayName || key;
        const text = disabled ? (
          <ListItemText
            primary={primary}
            secondary={t('schedule.temperatureSetpointLimits.error_short')}
            secondaryTypographyProps={{
              color: 'inherit',
            }}
          />
        ) : (
          primary
        );

        return {
          value: key,
          text,
          disabled,
          label: primary,
        };
      });
  }
  return [];
}
