import { createSelector } from '@reduxjs/toolkit';
import { filterNotEmpty } from '../../../shared/ArrayUtil';
import _ from 'lodash';
import { getDevicesByRegionSelector } from './getDevicesByRegionSelector';
import { DeviceWithIncidents } from '../Location.model';

const getDeviceTemperatureRestrictionSelector = createSelector(
  [getDevicesByRegionSelector],
  (devices) => {
    const listTemperaturesDevices = devices
      .map((device) =>
        Object.values(device.shelfSettings ?? {})
          .map(
            (shelfSetting) => shelfSetting.shelfStatus.temperatureSetpointLimits
          )
          .filter(filterNotEmpty)
      )
      .flat();

    return reduceMinMaxIntersection(listTemperaturesDevices);
  }
);

export const getReduceRestrictionFromDevices = (
  devices: DeviceWithIncidents[]
) => {
  // reduce device on list of temperature restrictions by zoneId
  // { [zoneId]: { min: number, max: number }[] }
  const zonesRestriction = devices.reduce((acc, device) => {
    Object.entries(device.shelfSettings ?? {}).forEach(([zoneId, setting]) => {
      if (!acc[zoneId]) {
        acc[zoneId] = [];
      }
      acc[zoneId].push(setting?.shelfStatus.temperatureSetpointLimits);
    });
    return acc;
  }, {} as { [zoneId: string]: { min: number; max: number }[] });

  // reduce temperature restrictions by zoneId
  // { [zoneId]: { min: number, max: number } }
  const zonesRestrictionReduced = Object.entries(zonesRestriction).reduce(
    (acc, [zoneId, restrictions]) => {
      acc[zoneId] = reduceMinMaxIntersection(
        restrictions.filter(filterNotEmpty)
      );
      return acc;
    },
    {} as { [zoneId: string]: { min?: number; max?: number } }
  );

  return zonesRestrictionReduced;
};

export function reduceMinMaxIntersection(
  list: { min?: number; max?: number }[]
) {
  return list.reduce(
    (acc, { min, max }) => {
      acc.min = _.max([acc.min, min]);
      acc.max = _.min([acc.max, max]);
      return acc;
    },
    { min: undefined, max: undefined } as { min?: number; max?: number }
  );
}
export default getDeviceTemperatureRestrictionSelector;
